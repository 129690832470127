<!--
* 创建人：罗兴
* 日 期：
* 描 述：
-->
<!--view 模板-->
<template>
  <div class="container">
    <div class="page-title">新生入住</div>
    <div class="tool">
      <div class="tool-left">
        <el-row :gutter="20">
          <el-col :span="12" class="toolitem">
            <span class="mr5 fcolor42">考生号/学号</span>
            <el-input
              v-model="kshxh"
              placeholder="请输入考生号/学号"
              size="small"
            ></el-input>
          </el-col>
          <el-col :span="12" class="toolitem">
            <span class="mr5 fcolor42">姓名</span>
            <el-input
              v-model="xsinfo.xm"
              :disabled="true"
              size="small"
            ></el-input>
          </el-col>
          <el-col :span="12" class="toolitem">
            <span class="mr5 fcolor42">性别</span>
            <el-input
              v-model="xsinfo.xb"
              :disabled="true"
              size="small"
            ></el-input>
          </el-col>
          <el-col :span="12" class="toolitem">
            <span class="mr5 fcolor42">院系</span>
            <el-input
              v-model="xsinfo.bmmc"
              :disabled="true"
              size="small"
            ></el-input>
          </el-col>
          <el-col :span="12" class="toolitem">
            <span class="mr5 fcolor42">专业</span>
            <el-input
              v-model="xsinfo.zymc"
              :disabled="true"
              size="small"
            ></el-input>
          </el-col>
          <el-col :span="12" class="toolitem">
            <el-button
              size="small"
              type="primary"
              style="margin-left: 95px"
              @click="searchxsinfo"
              >查找预设房间</el-button
            >
          </el-col>
        </el-row>
      </div>
      <div class="tool-right">
        <el-row :gutter="20">
          <el-col :span="24" class="toolitem">
            <span class="mr5 fcolor42">公寓楼</span>
            <el-select
              v-model="gyl"
              size="small"
              @change="selectothergy"
              clearable
            >
              <el-option
                v-for="(item, index) in gyllist"
                :key="index"
                :label="item.gylmc"
                :value="item.gylbh"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="24" class="toolitem">
            <span class="mr5 fcolor42">楼层</span>
            <el-select v-model="lc" size="small" clearable>
              <el-option
                v-for="(item, index) in glclist"
                :key="index"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="24" class="toolitem">
            <el-button
              size="small"
              type="primary"
              style="margin-left: 125px"
              @click="searchother"
              >查找其他房间</el-button
            >
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="c_table">
      <el-table
        :data="dataPageList"
        :height="windowHeight - 270"
        border
        style="width: 100%"
        header-cell-class-name="tableStyle"
        highlight-current-row
        :row-style="{ height: '20px' }"
        :cell-style="{ padding: '5px' }"
      >
        <el-table-column prop="bmmc" label="院系"> </el-table-column>
        <el-table-column prop="zymc" label="专业"> </el-table-column>
        <el-table-column prop="fjbh" label="房间号"> </el-table-column>
        <el-table-column prop="cws" label="床位数"> </el-table-column>
        <el-table-column prop="xbxz" label="性别限制"> </el-table-column>
        <el-table-column prop="sycws" label="剩余">
          <template slot-scope="scope">
            {{ scope.row.cws - scope.row.rzzrs }}
          </template>
        </el-table-column>
        <el-table-column label="入住">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.cws - scope.row.rzzrs > 0"
              type="text"
              size="small"
              @click="selectroom(scope.row.fjbh)"
              >入住</el-button
            >
            <el-button v-else style="color: #ccc" type="text" size="small"
              >入住</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<!-- javascript脚本-->
<script>
import sg from '../../api/gygl'
export default {
  data() {
    return {
      xsinfo: { ksh: '', xm: '', bmmc: '', zymc: '', xb: '' },
      kshxh: '',
      windowHeight: document.documentElement.clientHeight, // 实时屏幕高度
      keyword: '',
      keyValue: '',
      tableloading: true,
      buttonauth: {},
      dataPageList: [],
      gyl: null,
      gyllist: [],
      lc: null,
      glclist: [],
    }
  },
  created() {
    sg.getOtherList().then((res) => {
      if (res.code === 200) {
        this.gyllist = res.data
      }
    })
  },
  methods: {
    selectothergy(e) {
      this.glclist = []
      this.gyllist.forEach((item) => {
        if (item.gylbh === e) {
          for (var i = 1; i <= item.lcs; i++) {
            this.glclist.push(i)
          }
        }
      })
    },
    searchother() {
      if (!this.gyl) {
        this.$message.error('请选择一栋公寓楼')
      } else {
        sg.getKXZList({
          queryJson: JSON.stringify({ gylbh: this.gyl, lcs: this.lcs }),
        }).then((res) => {
          if (res.code === 200) {
            this.dataPageList = res.data
          }
        })
      }
    },
    searchxsinfo() {
      sg.getStuInfoToXSRZ(this.kshxh).then((res) => {
        if (res.code === 200) {
          if (res.data) {
            this.xsinfo = res.data
            this.xsinfo.xb = res.data.xbm === 1 ? '男' : '女'
            if (res.data.fjbh) {
              this.$message.error('该学生已分配房间')
            } else {
              sg.getKRZList(this.kshxh).then((r) => {
                if (r.code === 200) {
                  this.dataPageList = r.data
                }
              })
            }
          }
        } else {
          this.$message.error(res.message)
        }
      })
    },
    selectroom(fjbh) {
      this.$confirm('是否确认入住?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          sg.newxsrz({ KSHXH: this.kshxh, FJBH: fjbh }).then((res) => {
            if (res.code === 200) {
              this.$message.success(res.message)
            } else {
              this.$message.error(res.message)
            }
          })
        })
        .catch(() => {})
    },
  },
  mounted() {},
}
</script>
<!-- CSS样式 -->
<style scoped>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
}
.tool {
  display: flex;
  padding: 10px;
}
.tool-left {
  width: 50%;
}
.tool-right {
  width: 25%;
}
.page-title {
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-weight: bold;
  padding-left: 9px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
}
.c_table {
  height: 100%;
  overflow-y: auto;
}
.el-table >>> .tableStyle {
  background-color: #f8f8f8 !important;
  color: #333;
  font-weight: 400;
  padding: 5px 0;
}
.toolitem {
  display: flex;
  padding: 5px 0;
  align-items: center;
}
.toolitem span {
  width: 120px;
  text-align: right;
  margin-right: 5px;
}
</style>
